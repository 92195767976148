/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import {
  uploadImage,
  putInProduction,
  getIdProduction,
} from "../../services/PromotionServices";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

export default function PageEditProduction() {
  let { id } = useParams();
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImageString, setPreviewImageString] = useState(null);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [nameProducts, setNameProducts] = useState("");
  const [statusShow, setStatusShow] = useState("");
  const [price, setPrice] = useState("");
  const [commissionDiscount, setCommissionDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCurrentFile(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleClickDelete = () => {
    setPreviewImage(previewImageString);
    setCurrentFile(undefined);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const setClose = () => {
    navigate("/product_promotion");
  };

  useEffect(() => {
    handleGetId();
  }, []);

  const handleGetId = async () => {
    let responseData = await getIdProduction(id);
    setStatusShow(responseData.status);
    if (responseData.status === "success") {
      setPreviewImage(responseData.results.image);
      setPreviewImageString(responseData.results.image);
      setNameProducts(responseData.results.name_product);
      setPrice(responseData.results.price);
      setCommissionDiscount(responseData.results.commission_discount);
      setDescription(responseData.results.description);
      setCategory(responseData.results.category);
    } else if (responseData.status === "failed") {
      toast.error("Failed to fetch product details");
    }
  };

  const uploadImageUpdate = () => {
    setProgress(0);

    uploadImage(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        handleUpdateWithImage(response.link);
      })
      .catch((err) => {
        setProgress(0);
        setMessage(
          err.response?.data?.message || "Could not upload the Image!"
        );
        setPreviewImage(undefined);
      });
  };

  const handleUpdateWithImage = async (imagelink) => {
    const ratting = {
      rate: 0,
      count: 0,
    };
    let responseData = await putInProduction(
      id,
      nameProducts,
      price,
      commissionDiscount,
      description,
      category,
      `https://api.vn-traveloka.com/api-run/images${imagelink}`,
      ratting
    );
    if (responseData && responseData.status) {
      navigate("/product_promotion");
    } else {
      toast.error("Not added Production");
    }
  };

  const handleUpdate = async () => {
    const ratting = {
      rate: 0,
      count: 0,
    };
    let responseData = await putInProduction(
      id,
      nameProducts,
      price,
      commissionDiscount,
      description,
      category,
      previewImageString,
      ratting
    );
    if (responseData && responseData.status) {
      navigate("/product_promotion");
    } else {
      toast.error("Not added Production");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="mt-3 text-lg font-bold">Cập nhật Sản phẩm: {id}</h2>
      {statusShow === "failed" ? (
        <div className="flex flex-col items-center">
          <h2 className="text-xl">Trống Dữ Liệu</h2>
          <button
            className="mt-4 bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded"
            onClick={() => setClose()}
          >
            Đóng
          </button>
        </div>
      ) : (
        <div>
          <div className="flex flex-col items-center">
            {previewImage && (
              <div className="flex flex-col items-center mb-4">
                <img
                  className="rounded w-96 h-60 object-cover"
                  src={previewImage}
                  alt="Preview"
                />
                <br />
                {currentFile === undefined ? (
                  <div>
                    <label
                      htmlFor="uploadImage"
                      className="mt-3 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded cursor-pointer"
                    >
                      Chọn Ảnh
                    </label>
                    <input
                      type="file"
                      id="uploadImage"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </div>
                ) : (
                  <button
                    className="mt-3 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
                    onClick={() => handleClickDelete()}
                  >
                    Xoá
                  </button>
                )}
              </div>
            )}
          </div>
          {currentFile && (
            <div className="my-3">
              <div className="bg-blue-300 h-2 rounded">
                <div
                  className="bg-blue-600 h-full rounded"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <span>{progress}%</span>
            </div>
          )}
          {message && (
            <div className="alert bg-gray-300 p-2 rounded mt-3">{message}</div>
          )}
          <div className="mb-3">
            <label className="block text-sm font-medium">Tên Sản Phẩm</label>
            <input
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              value={nameProducts}
              onChange={(event) => setNameProducts(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium">
              Giá Sản Phẩm (VND)
            </label>
            <input
              type="number"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              value={price}
              onChange={(event) => setPrice(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium">Hoa Hồng (VND)</label>
            <input
              type="number"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              value={commissionDiscount}
              onChange={(event) => setCommissionDiscount(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium">
              Nội Dung Sản Phẩm
            </label>
            <input
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="block text-sm font-medium">Cấp bậc</label>
            <select
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              value={category}
              onChange={handleChange}
            >
              <option value={"VIP 1"}>VIP 1</option>
              <option value={"VIP 2"}>VIP 2</option>
              <option value={"VIP 3"}>VIP 3</option>
              <option value={"VIP 4"}>VIP 4</option>
              <option value={"VIP 5"}>VIP 5</option>
              <option value={"VIPBOSS"}>VIPBOSS</option>
            </select>
          </div>
          <div className="flex justify-between">
            <button
              className="bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded"
              onClick={() => setClose()}
            >
              Đóng
            </button>
            {currentFile === undefined ? (
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                disabled={
                  previewImage === undefined ||
                  nameProducts === "" ||
                  price === "" ||
                  commissionDiscount === "" ||
                  description === "" ||
                  category === ""
                }
                onClick={() => handleUpdate()}
              >
                Cập nhật sản phẩm
              </button>
            ) : (
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                disabled={
                  previewImage === undefined ||
                  nameProducts === "" ||
                  price === "" ||
                  commissionDiscount === "" ||
                  description === "" ||
                  category === ""
                }
                onClick={() => uploadImageUpdate()}
              >
                Cập nhật sản phẩm + Ảnh Mới
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
